import "@css/app.css";

/* JS */
import "lazysizes";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

Fancybox.bind("[data-fancybox]");

window.disableBodyScroll = disableBodyScroll;
window.enableBodyScroll = enableBodyScroll;

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

const appHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

window.appHeight = appHeight;

const header = document.querySelector(".site-header");

const headerHeight = () => {
	const doc = document.documentElement;
	doc.style.setProperty("--header-height", `${header.offsetHeight}px`);
	console.log(header.offsetHeight);
};
window.addEventListener("resize", headerHeight);
headerHeight();

window.headerHeight = headerHeight;

var scrollLockEnabled = false;
let theNav = document.querySelector(".mobile-nav");
function shouldScrollLock() {
	if (!scrollLockEnabled) {
		disableBodyScroll(theNav);
	} else {
		enableBodyScroll(theNav);
	}
	scrollLockEnabled = !scrollLockEnabled;
}

window.shouldScrollLock = shouldScrollLock;

class InlineVideo {
	constructor(el) {
		this._el = el;
		this._video = el.dataset.video;
		this._image = el.querySelector(".js-video__placeholder");

		this._el.addEventListener("click", this.handleClick.bind(this));
	}

	handleClick() {
		this._el.insertAdjacentHTML(
			"beforeend",
			`<iframe src="${this._video}?autoplay=1&rel=0" frameborder="0"></iframe>`
		);
		this._image.classList.add("fade-out");

		setTimeout(() => {
			this._image.remove();
		}, 700);

		this._el.removeEventListener("click", this.handleClick);
	}
}

Array.from(document.querySelectorAll(".js-video")).forEach((video) => {
	new InlineVideo(video);
});

const locationDataScript = document.querySelector("#location-data");
if (locationDataScript) {
	let locationData = locationDataScript.innerHTML;
	locationData = locationData.split("|");

	const banner = document.querySelector(".banner");
	const mapContainer = document.createElement("div");
	mapContainer.classList.add("absolute", "inset-0");

	const mapDiv = document.createElement("div");
	mapDiv.id = "map";
	mapDiv.style.width = "100%";
	mapDiv.style.height = "100%";

	mapContainer.appendChild(mapDiv);
	banner.appendChild(mapContainer);

	var currentZoom = getZoomLevel();

	mapboxgl.accessToken =
		"pk.eyJ1IjoiYm9yZGVyc2RldiIsImEiOiJjbGw2Z2docWQwZ3poM2tvMDdoYW5oZHh2In0.XuqNwk39J2A9mNYPkBsT8A";
	const map = new mapboxgl.Map({
		container: "map", // container ID
		style: "mapbox://styles/bordersdev/cll6i4ru500jq01qnezkf54bz", // style URL
		center: [-98.5795, 36.828175], // starting position [lng, lat]
		zoom: currentZoom, // starting zoom
		projection: "mercator",
		interactive: false,
	});

	locationData.forEach((loc) => {
		const parts = loc.split(",");
		const el = document.createElement("div");
		el.className = "marker";
		const marker = new mapboxgl.Marker(el)
			.setLngLat([parts[0], parts[1]])
			.addTo(map);

		marker.getElement().addEventListener("click", () => {
			window.location.href = `${parts[2]}`;
		});
	});

	window.addEventListener("resize", () => {
		let newZoom = getZoomLevel();
		if (newZoom != currentZoom) {
			currentZoom = newZoom;
			map.setZoom(newZoom);
		}
	});
}

function getZoomLevel() {
	const width = window.innerWidth;
	if (width < 1024) {
		return 2.5;
	}
	return 3.75;
}

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";

import Swiper from "swiper";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper/modules";

Array.from(document.querySelectorAll(".js-slider-banner")).forEach(
	(container) => {
		const slider = container.querySelector(".swiper");
		const pagination = container.querySelector(".swiper-pagination");
		const navigation = container.querySelector(".swiper-buttons");

		const swiper = new Swiper(slider, {
			modules: [Pagination, Navigation, EffectFade, Autoplay],
			loop: true,
			autoHeight: false,
			speed: 1000,
			effect: "fade",
			fadeEffect: {
				crossFade: true,
			},
			pagination: {
				el: pagination,
				clickable: true,
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			autoplay: {
				delay: 8000,
			},
		});
	}
);

import Rellax from "rellax";

function init() {
	let options = {
		center: true,
		breakpoints: [640, 768, 1024, 1280],
	};
	let rellax = new Rellax(".rellax", options);
	var rellax2 = new Rellax(".rellax2", {
		wrapper: ".side",
	});
}

import ScrollOut from "scroll-out";

ScrollOut({
	onShown(el) {
		el.classList.add("activated");
	},
	onHidden(el) {
		el.classList.remove("activated");
	},
});

document.addEventListener("DOMContentLoaded", () => {
	init();
});
